//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from "firebase/app";
import {
	getAuth,
	setPersistence,
	browserLocalPersistence,
} from "firebase/auth";

//* Add the Web App's Firebase configuration
const firebaseConfig = {
	apiKey: "AIzaSyCR1EH4Up_u_Fy2AjsKhyvvw75AxaaYJQg",
	authDomain: "romangram-165e1.firebaseapp.com",
	projectId: "romangram-165e1",
	storageBucket: "romangram-165e1.appspot.com",
	messagingSenderId: "265001186220",
	appId: "1:265001186220:web:56452624b555eed64d0009",
	measurementId: "G-BC4M4M8HN1",
};

//* Initialize Firebase
let firebase_app =
	getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
	.then(() => {
		console.log("Session persistence set to LOCAL");
	})
	.catch((error) => {
		console.error("Failed to set session persistence:", error);
	});

export { auth };
export default firebase_app;
